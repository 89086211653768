<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h4>Your IP address: <code>{{ client_ip }}</code></h4>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      client_ip: 'Fetching IP...', // Initial placeholder text for the IP address
    };
  },
  mounted() {
    // Fetch the IP address using ipify
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        this.client_ip = data.ip; // Set the client's IP address
      })
      .catch(error => {
        console.error('Error fetching IP:', error);
        this.client_ip = 'Error fetching IP'; // Error handling
      });
    document.title = 'khamidov.me'; // Set the document title
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
